<template>
  <div class="container">
    <div class="content-box">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-menu
            :default-active="routePath"
            class="el-menu-vertical-demo"
            background-color="#d7e7f3"
            text-color="#343434"
            active-text-color="#0359AC"
            router
            @select="handleSelect"
          >
            <el-menu-item index="/bwQuality/bwMora">
              <i class="iconfont icon-3_11sixiangpinde"></i>
              <span slot="title"> 思想品德</span>
            </el-menu-item>
            <el-menu-item index="/bwQuality/bwAc">
              <i class="iconfont icon-xueyeshuiping"></i>
              <span slot="title"> 学业水平</span>
            </el-menu-item>
            <el-menu-item index="/bwQuality/bwHealth">
              <i class="iconfont icon-shengxinjiankang"></i>
              <span slot="title"> 身心健康</span>
            </el-menu-item>
            <el-menu-item index="/bwQuality/bwArt">
              <i class="iconfont icon-yishuxiuyang"></i>
              <span slot="title"> 艺术素养</span>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :span="19">
          <router-view />
        </el-col>
      </el-row>
    </div>
    <!-- <div class="no-more">没有更多了</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      routePath: ""
    };
  },
  created() {
    this.getPath();
    this.$store.dispatch('GetTermList')
  },
  watch: {
    $route: "getPath",
  },
  methods: {
    getPath() {
		// console.log()
		this.routePath = this.$route.path
      /* if (this.$route.path.indexOf("/studentResults") != -1) {
        this.routePath = "/quality/morality";
      }else if(this.$route.path.indexOf("/healthStudent") != -1) {
        this.routePath = "/quality/health";
      }else if(this.$route.path.indexOf("/artStudent") != -1) {
        this.routePath = "/quality/Artistic";
      }else if(this.$route.path.indexOf("/academicStudent") != -1 || this.$route.path.indexOf("/usualDetails") != -1) {
        this.routePath = "/quality/Academic";
      } else {
        this.routePath = this.$route.path;
      } */
    },
    handleSelect(index) {
      // console.log(index);
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 18px 18px 18px 0;
    border-radius: 0 0 6px 6px;
    .el-menu {
      border: none;
      .el-menu-item {
        font-weight: bold;
        border-left: 4px solid transparent;
        i {
          font-size: 22px;
          font-weight: normal;
          color: #343434;
        }
        &.is-active {
          border-left: 4px solid #198af7 !important;
          background-color: #c3dcef !important;
          i {
            color: #0359ac;
          }
        }
      }
    }
  }
  .no-more {
    height: 50px;
    text-align: center;
    color: rgba(25, 138, 247);
    line-height: 50px;
    background: #cedfec;
    opacity: 0.46;
    border-radius: 6px;
    margin-top: 14px;
  }
}
</style>